import CustomCheckBox from "./CheckBoxGroup";
import {Box, useCheckboxGroup} from "@chakra-ui/react";

const CheckBox = (props) => {
  const { value, getCheckboxProps } = useCheckboxGroup({
    defaultValue: props.adminsChecked
  })

  props.setAdminsChecked(value);

  return (
    <Box display={"flex"} flexDirection={"column"} overFlow={"scroll"}>
      { props.admins.map(({ username, name, userId, canShare }, index) =>{
        return (<CustomCheckBox {...getCheckboxProps({value: userId, label: username ? username : name})}/>
        )
      }) }
    </Box>
  );
};

export default CheckBox;
