async function getRealTimeStats(data) {

  const channelList = await fetch(
    `${process.env.REACT_APP_BACKEND}/telegram/getUsersRT?id=${data}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const query = await channelList.json();

  return query;
}

export default getRealTimeStats;