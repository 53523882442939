import {
  Button, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import postShareMessageService from "../../services/postShareMessageService";

const ShareModal = ({ isOpen, onClose, formState, data, tg }) => {
  const handleShare = async () => {
    const channels = formState.selectedOptions.map((option) => option.value);
    // const backEndObject = channels, message: formState.textInput, data;

    const result = await postShareMessageService(channels, formState.textInput.replace(/\n/g, "%0A"), data);
    if(result.ok) {
      onClose();
      tg.showAlert("Message Sent! 🚀")
    }else{
      tg.showAlert("Something went wrong! 🤔")
    }
    // make share logic
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"xs"}>
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px)"
      />
      <ModalContent>
        <ModalHeader textAlign={"center"} style={{marginTop: "2em"}}>
          Do you want to share this message?
        </ModalHeader>
        <ModalCloseButton />
        {/*<ModalBody>Do you really want to share these message?</ModalBody>*/}

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose} w={"48%"}>
            Close
          </Button>
          <Button colorScheme={"green"} onClick={handleShare} w={"48%"}>
            Share
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default ShareModal;