
async function validateService(initData) {
  if (!initData) return;
  const response = await fetch(`${process.env.REACT_APP_BACKEND}/telegram/validate?${initData}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await response.json();
}

export default validateService;
