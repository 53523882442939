import {createBrowserRouter} from "react-router-dom";
import App from "../App";
import Landing from "../screens/landing/Landing";
import NotAllowed from "../screens/notAllowed/NotAllowed";
import React from "react";
import ManageAdmins from "../screens/manageAdmins/ManageAdmins";
import ShareMessage from "../screens/shareMessage/ShareMessage";
import MessageHistory from "../screens/messageHistory/MessageHistory";
import ChannelStats from "../screens/stats/channelStats";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>
  },
  {
    path: "/main",
    element: <Landing/>
  },
  {
    path: "/manage-admins",
    element: <ManageAdmins/>
  },
  {
    path: "/not-allowed",
    element: <NotAllowed/>
  },
  {
    path: "/share-message",
    element: <ShareMessage/>
  },
  {
    path: "/message-history",
    element: <MessageHistory/>
  },
  {
    path: "/stats",
    element: <ChannelStats/>
  }
]);

export default router;