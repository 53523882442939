async function getChannelStats(data) {

  const channelList = await fetch(
    `${process.env.REACT_APP_BACKEND}/telegram/getStats?id=${data}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const query = await channelList.json();

  const formattedChannels = query.channels.map(({ name, ChannelStats }) => ({
    label: name,
    stats: {
      labels: (ChannelStats.map(({ date }) => date)).reverse(),
      datasets: [
        {
          label: "Members Count",
          data: (ChannelStats.map(({ membersCount }) => membersCount)).reverse(),
        },
      ],
    }
  }));

  return formattedChannels;
}

export default getChannelStats;