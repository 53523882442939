import React, {useEffect, useState} from 'react';
import "../styles.css"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import {Box, Container, Text} from "@chakra-ui/react";
import validateService from "../../services/validateService";
import {useNavigate} from "react-router-dom";
import LoadingSpinner from "../../components/shared/LoadingSpinner";
import getChannelStats from "../../services/getChannelStats";
import getRealTimeStats from "../../services/getRealTimeStats";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Total Members Count',
    },
  },
};

const ChannelStats = () => {
  const [data, setData] = useState(undefined);
  const [chartData, setChartData] = useState(undefined);
  const [rtStats, setRtStats] = useState(undefined);

  const navigate = useNavigate();
  window.Telegram.WebApp.ready();
  window.Telegram.WebApp.BackButton.show();
  const tg = window.Telegram.WebApp;

  useEffect(() => {
    (async () => {
      const isValid = await validateService(window.Telegram.WebApp.initData);
      if(!isValid.value) navigate("/not-allowed");

      setData({
        user: JSON.parse(isValid.user),
        value: isValid.value,
        initData: window.Telegram.WebApp.initData,
      });

    })();
  }, [navigate]);

  useEffect(() => {
    window.Telegram.WebApp.BackButton.onClick(()=>{
      navigate("/main")
    })
  }, [navigate]);

  useEffect(() => {
    if (data?.user?.id === undefined) return;
    (async () => {
      const channelStats = await getChannelStats(data.user.id);
      setChartData(channelStats);
      const realTimeStats = await getRealTimeStats(data.user.id);
      setRtStats(realTimeStats);
    })();
  },[data]);

  if(data === undefined || chartData === undefined || rtStats === undefined) {
    return (
      <Box className="App-header" justifyContent={"center"}>
        <LoadingSpinner size={"xl"}/>
      </Box>
    )
  }

  const labels = chartData[0].stats.labels;
  const charts = chartData.map((channel) => ({
    labels,
    datasets: [{
      label: channel.label,
      data: channel.stats.datasets[0].data,
      borderColor: 'rgb(243,108,15)',
      backgroundColor: 'rgb(255,130,41)',
    }],
  }));

  return (
    <Box className={"App-header"}>
      <Header userData={data.user}/>
      <Container maxWidth={"90%"} minH={"300px"} justifyContent={"center"}>
        <Text fontSize={"md"} align={"center"}>Real Time Members Count</Text>
        <Box mb={"0.5em"}>
          <Box>
            <Text fontSize={"md"} align={"left"} color={"gray"}>· Total Members: {rtStats.totalMembers}</Text>
          </Box>
          {rtStats.realTimeStats.map((stat, index) => {
            return (
              <Box key={index}>
                <Text fontSize={"md"} align={"left"} color={"gray"}>· {stat.name}: {stat.membersCount}</Text>
              </Box>
            )}
          )}
        </Box>
        <Text fontSize={"md"} align={"center"}>Historical Members Count</Text>
        {charts.map((chart, index) => (
          <Box mb={"1em"} key={index}>
            <Line options={options} data={chart}/>
          </Box>
        ))}
      </Container>
      <Footer/>
    </Box>
  );
};

export default ChannelStats;
