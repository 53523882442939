import {Box, Text} from "@chakra-ui/react";

const MessageHistory = () => {
  return (
    <Box>
      <Text>Message History Page</Text>
    </Box>
  );
};

export default MessageHistory;
