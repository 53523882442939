async function postShareMessageService(channelList, message, userData) {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND}/telegram/shareMessage?${userData}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: message,
        channels: channelList,
        // userData: userData,
      }),
    }
  );
  const data = await response.json();
  return data;
}

export default postShareMessageService;
