import {Box, Button} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";

const MenuButton = ({children, onClick}) => {
  return (
    <Button
      marginY={"1em"}
      variant={"outline"}
      colorScheme="orange"
      onClick={onClick}
    >
      {children}
    </Button>
  )
};

const Menu = () => {
  const navigate = useNavigate();

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <MenuButton children={"Manage Admins"} onClick={()=>{ navigate("/manage-admins") }}/>
      <MenuButton children={"Share Message"} onClick={()=>{navigate("/share-message")}}/>
      <MenuButton children={"Channel Stats"} onClick={()=>{navigate("/stats")}}/>
    </Box>
  );
};

export default Menu;
