async function getChannelsListService(data) {
  const channelList = await fetch(
    `${process.env.REACT_APP_BACKEND}/telegram/getChannels?id=${data.user.id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const query = await channelList.json();
  const formatedChannels = query.channels.map(({ name, channelId }) => ({
    label: name,
    value: channelId,
  }));
  return formatedChannels;
}

export default getChannelsListService;
